.list-group-item.active {
  a, i {
    color: #fff!important;
  }
}
.data-value-alert i {
  color: darkorange;
}
.horizonal-list {
  list-style-type: none;
  padding-left: 0;

  li {
    float: left;
  }

  input {
    font-size: 1em;
  }
}
#bonder-info-tab-tabpane-speedtests {
  h4 {
    padding-bottom: 2rem;
  }
  h5 {
    color: #888;
    font-size: 1.1rem;
    padding-bottom: 1rem;
  }
}

#bonder-info-tab-tabpane-details {
  h5 {
    color: #888;
    font-size: 1.1rem;
    padding-bottom: 1rem;
  } 
}
