.prop-value {
  border-bottom: 2px dashed #0088cc;
}

.property-editor {
  list-style-type: none;
  padding-left: 0;

  li {
    float: left;
  }

  input {
    font-size: 1em;
  }
}
