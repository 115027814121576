.row {
  .sidebar {
    background-color: #dcdcdc;
    .nav-link {
      font-weight: 500;
      color: #333;
    }
  
    .nav-link.active {
      color: #ffffff;
    }
  }


  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }

  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }
}
