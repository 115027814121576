@import "bootstrap-icons/font/bootstrap-icons.css";
@import "../variables.scss"; // Need this setup before we import bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

html {
  height: -webkit-fill-available;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: .875rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-size: 1.6rem;
  padding-bottom: 1.2rem;
}
h2:not(.accordion-header) {
  font-size: 1.2rem;
  padding: 0.2rem 0 1rem 0;
}
h3 {
  font-size: 1rem;
  color: #666666;
  padding-bottom: 0.6rem;
}

#main {
  min-height: 90vh;
}

td {
  vertical-align: middle;
}

footer {
  a:hover i {
    color: $accent;
  }
  #app-footer-title {
    padding: 0.5rem 1rem;
  }
  .nav-link {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $accent;
      box-shadow: none;
    }
  }
}

.pagination {
  margin: 0;
}

dt {
  font-weight: 700;
}
