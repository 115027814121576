/*
 * Navbar
 */

 .navbar {
  .user-menu-dropdown {
    color: #fff;  
  }
  .user-menu-dropdown:hover {
    color: #888;
  }

  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;

    span {
      background: no-repeat url("../../assets/commsportal-logo.png") left center;
      background-size: contain;
      display: block;
      text-indent: -9999px;
    }
  }

  .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }

  .form-control-dark {
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
    color: #fff;
  }

  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
}

@media (max-width: 767.98px) {
  .navbar-brand span {
    width: 200px;
  }
}
