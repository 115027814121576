.form-signin {
  margin: auto;
  max-width: 330px;
  width: 100%;

  .form-floating:focus-within {
    z-index: 2;
  }

  .card {
    border-radius: 0.6rem;
  }
}
